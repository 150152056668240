<template>
  <div class="send">
    <Title />
    <section class="send-container">
      <div class="send-message">
        <span class="send-title">{{ $t("send_message02_1") }}</span>
        <span class="send-title">{{ $t("send_message02_2") }}</span>
        <span class="send-title">{{ $t("send_message02_3") }}</span>
        <div class="form-send-button" @click="$router.push('/akiya')">
          <span class="text_to_top">{{ $t("page17_exit") }}</span>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Title from "../components/title/Title.vue";
export default {
  name: "Send",
  components: {
    Title,
  },
};
</script>

<style scoped lang="scss">
.send {
  position: relative;
  height: 100%;
  width: 100%;
  font-family: "MS PGothic, 'Arial',YuGothic,'Yu Gothic','Hiragino Kaku Gothic ProN','ヒラギノ角ゴ ProN W3','メイリオ', Meiryo,'ＭＳ ゴシック',sans-serif;";

  .send-container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.7);
    background-blend-mode: lighten;
    background-size: cover;
    background-position: center;
  }

  .send-message {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
    padding: 20px;
    background-color: rgba(255, 255, 255);
    box-shadow: 0px 0px 30px 5px #e8f2f9;
    border-radius: 10px;
    z-index: 5;

    .send-title {
      font-size: max(22px, 2.5vw);
      font-weight: bold;
      text-align: center;
      margin-bottom: 30px;
      color: #132d7a;
    }
  }

  .form-send-button {
    margin: 30px;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    width: 20%;
    background-color: #132d7a;
    border-radius: 15px;
    cursor: pointer;
    transition: 0.5s;

    &.disabled {
      background-color: #ccc;
      cursor: text;
    }

    &:hover {
      opacity: 0.8;
    }

    .text_to_top {
      color: #fafafa;
      font-size: max(20px, 1.5vw);
      font-weight: bold;
      letter-spacing: 0.6rem;
    }
  }
}
</style>
